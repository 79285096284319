<template>

  <div>
    <button class=" bg-brandCol hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded-xl">
      {{ text }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonComp",
  props: {
    text: {
      default: "Button text",
      type: String,
    },
  }
}
</script>

<style scoped>

</style>