<template>

  <section class="section bgdark 2xl:rounded-xl">

    <div class="flex justify-center">
      <div class="container text-center lg:text-left">

        <div class="grid lg:grid-cols-2 mb-6">
          <div class="col-span-1">
            <h2 class="text-3xl lg:text-4xl text-brandCol pb-3 lg:pb-5 font-semibold tracking-tight ">
              Why choose DiginetID?
            </h2>
          </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-4 gap-y-3  lg:gap-6">

          <div v-for="item in items" :key="item" class="px-1 md:px-5 lg:px-0">

            <div class="justify-center">
              <div class="flex justify-center lg:justify-start">
                <img :src="item.icon" class="h-20"/>
              </div>

              <h2 class="  tracking-tight text-lg text-primary font-semibold py-2 lg:py-4 lg:text-xl lg:font-bold">
                {{ item.title }}
              </h2>
              <p class="text-left text-primary text-base font-light lg:text-lg leading-6">
                {{ item.subtitle }}
              </p>
            </div>

          </div>

        </div>
      </div>
    </div>

  </section>

</template>

<script>
export default {
  name: "WhyUs",
  data() {
    return {
      items: [
        {
          title: 'Security Focused',
          subtitle: 'By prioritizing accuracy, privacy, and compliance, we empower individuals to navigate the digital world with confidence. We adhere to the highest standards to build a secure foundation for your digital identity, allowing you to engage with various sectors without compromising your personal information.',
          "icon": require("@/assets/icons/lockIcon.png")
        },
        {
          title: 'Identity',
          subtitle: "Your identity is one of a kind, and we treat it with the utmost care and respect it deserves. At DiginetID we understand the significance of accuracy and reliability in the verification process. Our advanced technology and meticulous procedures ensure that your digital identity is not only verified seamlessly but also remains trustworthy across diverse industries.",
          "icon": require("@/assets/icons/docIcon.png")
        },
        {
          title: 'Ease of use',
          subtitle: "We believe that advanced security should enhance, not hinder, user experience. Our user-centric design and intuitive interface prioritize simplicity without compromising on security. Navigating and utilizing our digital identity verification solutions is designed to be straightforward, ensuring that individuals can effortlessly prove their identity with minimal effort.",
          "icon": require("@/assets/icons/speedIcon.png")
        },
        {
          title: 'Support',
          subtitle: "Customer satisfaction is at the heart of our service. Our dedicated support team is committed to ensuring that you have a positive and hassle-free experience with our solutions. Should you have any questions, encounter challenges, or need guidance, our support experts are readily available to assist you.",
          "icon": require("@/assets/icons/supportIcon.png")
        },
      ],
    }
  },

}
</script>

<style scoped>

.section {
  @apply py-10 lg:py-14 px-5;
}

.bgdark {
  @apply bg-lightBg;
}
</style>