<template>

  <nav class="bg-white border-gray-200 dark:bg-gray-900">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">

      <a href="/" class="flex items-center">
        <img src="@/assets/logos/logoMain.svg" class="h-8" alt="DiginetID Logo"/>
      </a>

      <button @click="toggleMenu()" type="button"
              class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
      </button>

      <div class="hidden w-full md:block md:w-auto" id="mobileMenu" :key="page">
        <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
          <li>
            <a @click="goToHome()" href="#" class="core"
               :class="page === 'home' ? 'active' : 'inactive'">Individual</a>
          </li>
          <li>
            <a @click="goToBusiness()" class="core"
               :class="page === 'business' ? 'active' : 'inactive'">Business</a>
          </li>
          <li>
            <a @click="goToPartner()" class="core"
               :class="page === 'partner' ? 'active' : 'inactive'">Partner</a>
          </li>
          <li>
            <a @click="goToContact()" class="core"
               :class="page === 'contact' ? 'active' : 'inactive'">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>


</template>

<script>
import router from "@/router";

export default {
  name: "NavbarComp",
  props: {
    page: {
      type: String,
      default: "home"
    },
  },
  methods: {
    toggleMenu() {
      const menu = document.getElementById('mobileMenu');
      menu.classList.toggle('hidden');
    },
    goToHome() {
      router.push({name: 'Home'});
    },
    goToBusiness() {
      router.push({name: 'Business'});
    },
    goToPartner() {
      router.push({name: 'Partner'});
    },
    goToContact(){
      //router.push({name: 'Home'});
      this.scrollToContact();
    },
    scrollToContact() {
      let elem = document.getElementById("contact");

      const y = elem.getBoundingClientRect().top + window.scrollY;
      window.scroll({
        top: y,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>

.core{
  @apply block py-2 pl-3 pr-4 cursor-pointer md:p-0  tracking-tight
}

.active {
  @apply  text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700;
}

.inactive {
  @apply text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700;
}

</style>