<template>

  <NavbarComp page="partner"/>

  <div class="2xl:w-full 2xl:flex 2xl:justify-center">
    <div class="2xl:w-4/5">

      <LandingComp title="Partnerships"
                   content="At DiginetID, we have established strong partnerships with industry-leading organizations that share our commitment to revolutionizing compliance processes. These collaborations enable us to deliver comprehensive and efficient solutions to our customers."
      />

      <InfoComp :items="infoCompData" class="bg-lightBg 2xl:rounded-xl"/>

    </div>
  </div>

  <FooterComp/>

</template>

<script>
import FooterComp from "@/components/FooterComp";
import NavbarComp from "@/components/NavbarComp";
import LandingComp from "@/components/LandingComp";
import InfoComp from "@/components/InfoComp";

export default {
  name: "PartnerView",
  components: {InfoComp, LandingComp, FooterComp, NavbarComp},
  data() {
    return {
      infoCompData: [
        {
          title: 'Expanded Market Reach and Customer Base',
          subtitle: "Partnering with DiginetID opens up new opportunities for vendors to access a broader market and customer base. By aligning with DiginetID, vendors can tap into our extensive network of customers and potential clients. This collaboration enables vendors to reach new market segments and geographies, expanding their business reach and unlocking new growth potential.",
        },
        {
          title: 'Integration Opportunities',
          subtitle: "DiginetID offers seamless integration capabilities, allowing partner vendors to integrate their solutions into the DiginetID ecosystem. Through this integration, vendors can provide a unified compliance experience to customers by seamlessly combining their offerings with DiginetID's platform. This integration eliminates the need for customers to manage multiple disparate systems, streamlining their compliance processes and enhancing efficiency. By integrating with DiginetID, vendors can offer a comprehensive and seamless compliance solution, strengthening their value proposition and attracting customers seeking an all-in-one compliance platform.."
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>