<template>

  <section class="section">

    <div class="flex justify-center">
      <div class="container">

        <div class="grid grid-cols-1 md:grid-cols-3">

          <div class="col-span-1 flex justify-center w-full">

            <div class="h-full flex justify-center">
              <img class="w-2/3" src="@/assets/logos/logoShort.svg"/>
            </div>

          </div>

          <div class="col-span-2">

            <div class="grid grid-cols-1">

              <div v-for="item in items" :key="item" class="py-2">

                <h2 class=" tracking-tight text-4xl text-primary font-bold py-2 lg:py-4 leading-9">
                  {{ item.title }}
                </h2>
                <p class="text-left text-primary text-base font-light lg:text-lg leading-6">
                  {{ item.subtitle }}
                </p>


              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

  </section>

</template>

<script>
export default {
  name: "InfoComp",
  props: {
    items: Array,
  },
}
</script>

<style scoped>

.section {
  @apply py-10 lg:py-14 px-5;
}
</style>