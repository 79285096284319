<template>

  <NavbarComp page="home"/>

  <div class="2xl:w-full 2xl:flex 2xl:justify-center">
    <div class="2xl:w-4/5">

      <LandingComp title="Shaping the future of digital identity"
                   content="We make it safer for people to prove who they are and easier for businesses to stay compliant with industry and government regulations"
      />
    </div>
  </div>

  <IndustryInfo :items="industryData"
                title="Transforming industries with our trusted digital ecosystem and cutting-edge online verification solutions"
  />

  <div class="2xl:w-full 2xl:flex 2xl:justify-center">
    <div class="2xl:w-4/5">

      <HomeExtraDetails :data="detailsData"/>

      <SmallInfo :data="smallInfoData"/>

      <InfoComp :items="infoCompData"/>

      <WhyUs/>

    </div>
  </div>

  <FooterComp/>

</template>

<script>

import NavbarComp from "@/components/NavbarComp";
import FooterComp from "@/components/FooterComp";
import LandingComp from "@/components/LandingComp";
import WhyUs from "@/components/WhyUs";
import InfoComp from "@/components/InfoComp";
import HomeExtraDetails from "@/components/HomeExtraDetails";
import SmallInfo from "@/components/SmallInfo";
import IndustryInfo from "@/components/IndustryInfo";

export default {
  name: "HomeView",
  components: {IndustryInfo, SmallInfo, HomeExtraDetails, InfoComp, WhyUs, LandingComp, FooterComp, NavbarComp},
  data() {
    return {
      infoCompData: [
        {
          title: 'Prove Your Identity',
          subtitle: "Use our portal or mobile app to share access to your digital identity with businesses we have partnered with for online or in-store verification",
        },
        {
          title: 'Prove Your Age',
          subtitle: "Our Digital Internet Identity enables you to instantly verify your age online using our advanced technology, such as geometric recognition or a password-protected digital solution.",
        },
        {
          title: 'Instantly Verify Yourself',
          subtitle: "Provide access to required documentation, such as proof of address, driving license, or government ID, to quickly and accurately verify your identity. We ensure correct spelling, grammar, and layout for a seamless verification process.",
        },
      ],
      detailsData: {
        title: 'Safely store your details, securely prove who you are',
        subtitle: "DiginetID is a secure mobile and web application that revolutionizes digital identity verification. With our solution, you only need to verify yourself once by uploading your personal documents such as your Driving License, Government ID, and Proof of Address into an encrypted file.",
        subtitle2: "This process generates your validated Digital Internet Identity, empowering you to conveniently prove your identity both in-store and online, all from the palm of your hand. Say goodbye to repetitive verification processes and embrace the simplicity and security of DiginetID.",
      },
      smallInfoData: {
        title: 'Built with security and privacy as a priority',
        subtitle: "Our systems are built in a way that means we cannot mine or sell data to third parties. Once we’ve completed our security checks, we can’t access any user details. We’re trusted by regulators for adhering to the highest standards of security.",
      },
      industryData: [
        {
          title: 'Cryptocurrency & Virtual Assets',
          icon: require("@/assets/icons/moneyIcon.png")
        },
        {
          title: 'Securities & Investment',
          icon: require("@/assets/icons/chartIcon.png")
        },
        {
          title: 'Insurance',
          icon: require("@/assets/icons/currIcon.png")
        },
        {
          title: 'Healthcare',
          icon: require("@/assets/icons/healthIcon.png")
        },
        {
          title: 'Banking & Financial Services',
          icon: require("@/assets/icons/bankIcon.png")
        },
        {
          title: 'Non-Profit Organizations',
          icon: require("@/assets/icons/orgIcon.png")
        },
        {
          title: 'Professional Services',
          icon: require("@/assets/icons/docIcon.png")
        },
        {
          title: 'Real Estate',
          icon: require("@/assets/icons/homeIcon.png")
        },
        {
          title: 'Retail & E-commerce',
          icon: require("@/assets/icons/shopIcon.png")
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>