<template>

  <div class="flex h-screen flex-col">

    <NavbarComp page="home"/>

    <div class="2xl:w-full 2xl:flex 2xl:justify-center">
      <div class="2xl:w-4/5">

        <LandingComp title="Error 404" :content="getPageText()" :error="true"/>

      </div>
    </div>

    <div class="flex-1"/>

    <FooterComp :hideContact="true"/>
  </div>


</template>

<script>
import router from "@/router";

import NavbarComp from "@/components/NavbarComp";
import FooterComp from "@/components/FooterComp";
import LandingComp from "@/components/LandingComp";

export default {
  name: "ErrorView",
  components: {LandingComp, FooterComp, NavbarComp},
  created() {
    setTimeout(() => this.$router.push({name: 'Home'}), 3000);
  },
  methods: {
    getPageText() {
      return "Url " + router.currentRoute.value.fullPath + " was not found";
    }
  }
}
</script>

<style scoped>

</style>