<template>

  <section class="w-full">

    <div class="flex w-full justify-center">
      <div class="w-full px-5 md:w-2/3 2xl:w-1/3 text-center">
        <h2 class=" tracking-tight text-xl text-primary font-bold">
          {{ title }}
        </h2>
      </div>
    </div>


    <div class="relative flex overflow-x-hidden">

      <div class="py-8 animate-marquee whitespace-nowrap flex">
        <IndustryInfoInner v-for="item in items" :key="item" :title="item.title" :icon="item.icon"/>
      </div>

      <div class="absolute top-0 py-8 animate-marquee2 whitespace-nowrap flex">
        <IndustryInfoInner v-for="item in items" :key="item" :title="item.title" :icon="item.icon"/>
      </div>

    </div>

  </section>


</template>

<script>
import IndustryInfoInner from "@/components/IndustryInfoInner";

export default {
  name: "IndustryInfo",
  components: {IndustryInfoInner},
  props: {
    title: String,
    items: Array,
  }
}
</script>

<style scoped>

</style>