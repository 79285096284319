<template>

  <section>

    <div class="flex justify-center px-4 md:px-10 lg:px-20">
      <div class="container">

        <div class="grid grid-cols-1 md:grid-cols-5 bg-lightBg rounded-xl">

          <div class="md:col-span-3 py-2 lg:py-10 px-5">

            <div class="grid grid-cols-1 h-full flex items-center">

              <div class="py-5 md:py-2">

                <h2 class=" tracking-tight text-2xl md:text-4xl text-primary font-bold py-2 lg:py-4">
                  {{ data.title }}
                </h2>
                <p class="text-left text-primary text-base py-3">
                  {{ data.subtitle }}
                </p>

                <p class="text-left text-primary text-base md:text-base">
                  {{ data.subtitle2 }}
                </p>

              </div>

            </div>

          </div>

          <div class="md:col-span-2 flex justify-center w-full">

            <div class="h-full flex justify-center">
              <img class="rounded-b-xl md:rounded-bl-none md:rounded-r-xl" src="@/assets/stock/girl2.png"/>
            </div>

          </div>

        </div>
      </div>
    </div>

  </section>

</template>

<script>
export default {
  name: "HomeExtraDetails",
  props: {
    data: Object,
  },
}
</script>

<style scoped>

</style>