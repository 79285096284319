<template>

  <span class="px-8 lg:px-12 text-xl flex items-center">
    <img class="h-10 inline-block" :src="icon"/>
    <span class="pl-2 font-semibold  tracking-tighter">{{ title }}</span>
  </span>

</template>

<script>
export default {
  name: "IndustryInfoInner",
  props: {
    title: String,
    icon: String
  }
}
</script>

<style scoped>
</style>