<template>

  <div class="w-full flex justify-center min-h-halfish items-center py-5">

    <div class="container h-full px-5 lg:px-12 text-center">

      <div class="w-full h-full flex">

        <div :class="{'md:w-1/3 lg:w-1/2' : !error}"/>

        <div :class="{'w-full' : error}">
          <h1 class="font-bold text-4xl md:text-5xl 2xl:text-6xl tracking-tight ">{{ title }}</h1>
          <p class="pt-5 px-0 md:px-6 font-semibold text-lg tracking-tight ">{{ content }}</p>
          <p v-if="error" class="px-0 md:px-6 font-semibold tracking-tight ">Redirecting to Home</p>
        </div>

        <div :class="{'md:w-1/3 lg:w-1/2' : !error}"/>

      </div>


      <div v-if="!error">

        <ButtonComp text="Contact us" class="pt-5" @click="scrollToContact"/>

      </div>


    </div>


  </div>


</template>

<script>
import ButtonComp from "@/components/ButtonComp";
import router from "@/router";

export default {
  name: "LandingComp",
  components: {ButtonComp},
  props: {
    title: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    scrollToContact() {
      let elem = document.getElementById("contact");

      const y = elem.getBoundingClientRect().top + window.scrollY;
      window.scroll({
        top: y,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>

</style>