<template>
  <NavbarComp page="business"/>

  <div class="2xl:w-full 2xl:flex 2xl:justify-center">
    <div class="2xl:w-w-4/5">

      <LandingComp
          title="The future of Compliance"
          content="The DiginetID ecosystem is a comprehensive one-stop shop for businesses seeking compliance solutions. We specialize in essential areas such as AML and KYC, providing streamlined and efficient processes on a global scale."
      />

      <InfoComp :items="infoCompData" class="bg-lightBg 2xl:rounded-xl"/>

      <OurServices/>

      <WhyUs/>

    </div>
  </div>

  <FooterComp/>
</template>

<script>
import FooterComp from "@/components/FooterComp";
import NavbarComp from "@/components/NavbarComp";
import LandingComp from "@/components/LandingComp";
import WhyUs from "@/components/WhyUs";
import InfoComp from "@/components/InfoComp";
import OurServices from "@/components/OurServices";

export default {
  name: "BusinessView",
  components: {OurServices, InfoComp, WhyUs, FooterComp, NavbarComp, LandingComp},
  data() {
    return {
      infoCompData: [
        {
          title: 'Streamlined Compliance',
          subtitle: "DiginetID offers businesses a streamlined compliance solution, consolidating Know Your Customer (KYC) and anti-money laundering (AML) processes into a single platform. It simplifies compliance workflows, reduces manual errors, and ensures regulatory adherence.",
        },
        {
          title: 'Integrated Vendor Management',
          subtitle: "With DiginetID, businesses can integrate multiple compliance vendors and solutions, eliminating the need to engage with multiple providers separately. The platform allows for seamless vendor management, simplifying operations and reducing the complexities associated with compliance.",
        },
        {
          title: 'Enhanced Efficiency and Risk Mitigation',
          subtitle: "DiginetID automates critical compliance tasks, leveraging advanced verification technologies and customizable workflows. It improves efficiency, reduces manual effort, and mitigates the risks of financial crimes. By centralizing compliance efforts, businesses can save time, resources, and costs while maintaining a high level of compliance and customer due diligence.",
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>