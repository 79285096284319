<template>

  <section class="section">

    <div class="flex justify-center">
      <div class="container">

        <div class="grid grid-cols-1">

          <div class="text-center flex justify-center">
            <div class="grid grid-cols-1 md:grid-cols-5">

              <div class="w-full md:col-span-3 md:col-start-2">
                <h2 class="text-3xl lg:text-4xl text-brandCol font-semibold tracking-tight ">
                  Our Services
                </h2>
                <p class="text-base lg:text-lg text-primary">
                  Our verification solutions can be seamlessly integrated into your website, app, terminal or other
                  custom product.
                </p>

              </div>
            </div>

          </div>


          <div class="w-full grid gap-2 gap-y-5 sm:grid-cols-2 md:grid-cols-4 pt-5">

            <div v-for="item in items" :key="item" class="bg-lightBg rounded-xl border border-gray-200">

              <div class="justify-center p-2">
                <div class="flex justify-start">
                  <img :src="item.icon" class="h-12"/>
                </div>

                <h2 class="  tracking-tight text-lg text-primary font-semibold py-2 lg:py-4 lg:text-xl lg:font-bold leading-5">
                  {{ item.title }}
                </h2>
                <p class="text-left text-primary text-sm font-light lg:text-lg">
                  {{ item.subtitle }}
                </p>
              </div>

            </div>


          </div>

        </div>

      </div>
    </div>

  </section>

</template>

<script>
export default {
  name: "OurServices",
  data() {
    return {
      items: [
        {
          title: 'Data verification',
          subtitle: "Efficient and robust verification of digital identity with a focus on speed and security.",
          "icon": require("@/assets/icons/lockIcon.png")
        },
        {
          title: 'Document verification',
          subtitle: "Seamless identity document verification process incorporating frictionless ID documentation and advanced biometric authentication techniques.",
          "icon": require("@/assets/icons/docIcon.png")
        },
        {
          title: 'Customer onboarding',
          subtitle: "Accelerating the Onboarding Process and Ensuring Fraud Prevention.",
          "icon": require("@/assets/icons/speedIcon.png")
        },
        {
          title: 'Age verification',
          subtitle: "Enhancing Age Verification Solutions: Safeguarding Against Underage Access to Restricted Content, Services, Products, and Venues.",
          "icon": require("@/assets/icons/supportIcon.png")
        },
        {
          title: 'Investigation & tracking',
          subtitle: "Customize Your Investigation for Real-World Impact.",
          "icon": require("@/assets/icons/lockIcon.png")
        },
        {
          title: 'Fraud prevention',
          subtitle: "Defend your business against current and future trend topologies reword this in professional manner.",
          "icon": require("@/assets/icons/lockIcon.png")
        },
        {
          title: 'Address verification',
          subtitle: "Enhance the Accuracy and Quality of Your Customer Address Data.",
          "icon": require("@/assets/icons/lockIcon.png")
        },
        {
          title: 'Compliance',
          subtitle: "Accelerating the Onboarding Process and Ensuring Fraud Prevention.",
          "icon": require("@/assets/icons/lockIcon.png")
        },
      ],
    }
  }
}
</script>

<style scoped>

.section {
  @apply py-10 lg:py-14 px-5;
}
</style>