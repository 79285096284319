<template>

  <section>

    <div class="w-full pt-10 px-5 flex justify-center">
      <div class="text-center md:w-3/5">
        <div class="grid grid-cols-1">

          <h2 class=" tracking-tight text-3xl md:text-4xl text-primary font-bold py-2">
            {{ data.title }}
          </h2>
          <p class="text-left text-primary text-lg">
            {{ data.subtitle }}
          </p>

        </div>
      </div>
    </div>

  </section>

</template>

<script>
export default {
  name: "SmallInfo",
  props: {
    data: Object,
  },
}
</script>

<style scoped>

</style>