<template>


  <section id="contact" class="pt-5 px-5">

    <div class="flex justify-center">
      <div class="container ">

        <div class="relative flex items-top justify-center sm:items-center sm:pt-0">
          <div class="grid grid-cols-1 md:grid-cols-2">

            <div class="max-w-form">

              <h1 class="text-2xl  sm:text-3xl text-brandCol font-semibold tracking-tight">
                Contact Now
              </h1>

              <div class=" tracking-tight font-semibold py-1">
                <p>We'd love to hear from you!</p>
                <p>Let's get in touch</p>
              </div>

              <a href="mailto:Jharney@DiginetID.com" class="cursor-pointer">
                <div class="flex w-full items-center pt-1 pb-2">
                  <div class="h-6 w-6 bg-white h-full rounded-full p-1 border-brandCol border">
                    <img src="@/assets/icons/emailIcon.svg"/>
                  </div>

                  <span class="text-sm hover:text-brandCol ml-2">Jharney@DiginetID.com</span>
                </div>
              </a>

              <div class="flex w-full items-center">
                <div class="h-8 w-8 bg-white h-full rounded-full p-1 border-brandCol border">
                  <img src="@/assets/icons/placeIcon.svg"/>
                </div>

                <span class="text-sm ml-2">DiginetID, Southeast Technological, University, West Campus, Woodstown, Carriganore, Co. Waterford.</span>
              </div>

            </div>


            <div v-if="this.otherMsg.length === 0 && !showLoading"
                 class="grid grid-cols-1 gap-x-3 gap-y-2 md:grid-cols-2 pt-3 md:pt-0">

              <div class="col-span-1 boxpad">
                <input type="text" name="name" id="name" placeholder="Full Name" class="textbox" v-model="fullName">
              </div>

              <div class="col-span-1 boxpad">
                <input type="text" name="company" id="company" placeholder="Company"
                       class="textbox" v-model="company">
              </div>

              <div class="col-span-1 boxpad">
                <input type="email" name="email" id="email" placeholder="Email" class="textbox" v-model="email">
              </div>

              <div class="col-span-1 boxpad">
                <input type="tel" name="phone" id="phone" placeholder="Phone Number"
                       class="textbox" v-model="number">
              </div>

              <div class="col-span-1 md:col-span-2">
                  <textarea rows="2" id="query" placeholder="Write your message here"
                            class="textbox w-full" v-model="content"
                  />

              </div>

              <div class="w-full col-span-1 md:col-span-2 md:px-5">

                <div class="text-center pt-1 pb-3" v-if="errorMsg">
                  <span class="text-sm font-bold">{{ errorMsg }}</span>
                </div>

                <button type="submit" @click="send"
                        class="bg-brandCol hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded-xl w-full">
                  Submit Message
                </button>

              </div>

            </div>

            <div v-else-if="showLoading"
                 class="w-full h-full flex justify-center items-center px-2 py-2 mt-2 md:mt-0 max-w-form">
              <div style="border-top-color:transparent"
                   class="h-20 w-20 border-4 border-brandCol border-solid rounded-full animate-spin"/>
            </div>

            <div v-else class="w-full flex justify-center items-center px-2 py-2 mt-2 md:mt-0 max-w-form">
              <span class="font-bold text-lg md:text-xl lg:text-2xl  tracking-tight"
                    :key="otherMsg">{{ otherMsg }}</span>
            </div>

          </div>
        </div>
      </div>

    </div>

  </section>


</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      fullName: "",
      company: "",
      email: "",
      number: "",
      content: "",
      errorMsg: "",
      otherMsg: "",
      showLoading: false,
    }
  },
  methods: {
    async send() {
      this.errorMsg = "";

      // Check if fields are valid
      let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      // Perform validation
      if (this.fullName.trim() === '') {
        this.errorMsg = 'Full Name is required';
      } else if (this.company.trim() === '') {
        this.errorMsg = 'Company is required';
      } else if (this.email.trim() === '' || !this.email.match(emailRegex)) {
        this.errorMsg = 'Email is required and must be a valid email address';
      } else if (this.number.trim() === '') {
        this.errorMsg = 'Phone Number is required';
      } else if (this.content.trim() === '') {
        this.errorMsg = 'Content is required';
      }

      if (this.errorMsg.length > 0) {
        return false;
      } else {

        this.showLoading = true;

        let body = {
          fullName: this.fullName,
          company: this.company,
          email: this.email,
          number: this.number,
          content: this.content,
        }

        const res = await fetch("/contactform", {
          "method": 'POST',
          "headers": {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body)
        });

        let text = await res.text();

        this.showLoading = false;

        if (text === "true") {
          this.otherMsg = "Thank you for your query. We'll get back to you as soon as possible";
        }

      }

    }
  }
}
</script>

<style scoped>
.textbox {
  @apply w-full py-2 px-2 rounded-lg border border-gray-600 text-gray-800 font-semibold focus:border-brandCol;
}

.boxpad {
  @apply w-full;
}
</style>