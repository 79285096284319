<template>

  <RouterView>

  </RouterView>

</template>

<script>
import router from "@/router";

export default {
  name: 'App',
}
</script>

<style>

</style>
