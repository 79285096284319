<template>

  <ContactUs v-if="!hideContact"/>

  <footer class="shadow-inner bg-brandCol h-20 mt-5 w-full px-5 lg:px-12">

    <div class="h-full w-full flex justify-center">

      <div class="container grid place-items-center grid-cols-2 sm:grid-cols-3 h-full">

        <div class="col-span-1 w-full flex items-center hidden sm:block">
          <img src="@/assets/logos/logoMainWhite.svg" class="h-8" alt="DiginetID Logo"/>
        </div>

        <div class="col-span-1 w-full flex justify-start sm:justify-center">
          <a href="https://www.linkedin.com/company/diginetid/" class="text-gray-100 ml-3">
            <svg
                aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in"
                class="svg-inline--fa fa-linkedin-in h-6 w-6" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
              <path
                  fill="currentColor"
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
              ></path>
            </svg>
          </a>
        </div>

        <div class="col-span-1 w-full flex justify-end items-center">
          <span class="text-gray-200 text-sm">©Copyright. <span
              class="whitespace-nowrap">DiginetID, Inc. 2023</span></span>
        </div>

      </div>

    </div>


  </footer>
</template>

<script>
import ContactUs from "@/components/ContactUs";

export default {
  name: "FooterComp",
  components: {ContactUs},
  props: {
    hideContact: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>

</style>