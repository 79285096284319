import {createRouter, createWebHistory} from "vue-router";

import HomeView from "@/views/HomeView";
import BusinessView from "@/views/BusinessView";
import PartnerView from "@/views/PartnerView";
import ErrorView from "@/views/ErrorView";

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomeView,
    },
    {
        path: "/partner",
        name: "Partner",
        component: PartnerView,
    },
    {
        path: "/business",
        name: "Business",
        component: BusinessView,
    },
    {
        path: '/:catchAll(.*)*', //catch all to send a 404
        name: 'Error',
        component: ErrorView,
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
